<template>
  <div class="">
    <div class="flex justify-between items-center mb-6">
      <div>
        <h1 class="text-4xl">Agent Transactions</h1>
        <!-- <p class="text-sm text-gray-400">Welcome to your dashboard</p> -->
      </div>

      <div class="flex">
        <div>
          <input
            class="p-2 border w-80 rounded"
            v-model="filter"
            @keyup="search()"
            type="search"
            placeholder="Search by customer or meter number"
          />
        </div>
        <button @click="rqFilter()" class="mx-2">
          <span>
            <i
              :class="{ 'text-blue-600': onFilter }"
              class="bx bx-filter-alt text-2xl"
            ></i>
          </span>
        </button>
      </div>
    </div>

    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-0 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
          >
            <div
              v-if="onFilter"
              class="flex grid-flow-col gap-4 bg-gray-50 p-3"
            >
              <div class="flex flex-col w-80">
                <label class="text-gray-600" for="branch"
                  >Filter by date
                </label>
                <input
                  class="p-2 border rounded text-xs"
                  type="date"
                  name="date"
                  @change="loadData(1, filter)"
                  v-model="filterDate"
                />
              </div>
            </div>
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Customer
                  </th>

                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Agent
                  </th>

                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Amount/Units
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Date/Time
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Status
                  </th>

                  <th scope="col" class="relative px-6 py-3">
                    <span class="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>

              <tbody v-if="isLoading">
                <tr class="">
                  <td colspan="7">
                    <spinner :show="isLoading" :content="`Loading `" />
                  </td>
                </tr>
              </tbody>
              <tbody v-else class="bg-white divide-y divide-gray-200">
                <tr v-for="(transaction, i) in transactions.data" :key="i">
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="ml-4">
                        <div class="text-sm font-medium text-gray-900">
                          {{ transaction.customer }}
                        </div>
                        <div class="text-sm text-gray-500">
                          {{ transaction.meter_serial }}
                        </div>
                      </div>
                    </div>
                  </td>

                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-sm text-gray-900">
                      {{ transaction.staff }}
                    </div>
                  </td>

                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-sm font-bold text-gray-900">
                      GMD {{ formatPrice(transaction.total) }} /
                      {{ transaction.units }}.kWh
                    </div>
                  </td>

                  <td class="px-6 py-4 w-20 whitespace-nowrap">
                    <div class="text-sm font-bold text-gray-900">
                      {{
                        transaction.created | moment("Do MMM, YYYY, h:mm:ss a")
                      }}
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <span
                      class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
                    >
                      Completed
                    </span>
                  </td>

                  <td
                    class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                  >
                    <!-- here 1 -->

                    <button
                      @click="viewTrans(transaction)"
                      class="text-indigo-600 hover:text-indigo-900"
                    >
                      View
                    </button>
                  </td>
                </tr>

                <!-- More people... -->
              </tbody>
            </table>

            <div class="border-t" v-if="transactions">
              <pagination :data="transactions" :midSize="2" @clicked="loadData">
              </pagination>
            </div>
            <!-- Here 4 -->
            <modal
              width="50%"
              height="100%"
              :adaptive="true"
              :scrollable="true"
              :resizable="true"
              name="example"
            >
              <div class="flex justify-center items-center my-3">
                <div class="flex">
                  <div>
                    <button
                      @click="print()"
                      class="uppercase text-gray-700 font-bold py-3 px-4 border border-gray-400 rounded-lg hover:bg-gray-100 text-white"
                    >
                      <i class="bx bx-printer"></i>

                      Print
                    </button>
                  </div>
                </div>
              </div>
              <transaction :data="selectedTransaction" />
            </modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import Pagination from "../../components/Pagination";
import FormatPrice from "@/mixins/FormatPrice";
import Transaction from "../Transactions/Transaction";
import { mapGetters } from "vuex";
import checkPermissionExist from "../../mixins/checkPermissionExist";

export default {
  middleware: "auth",
  layout: "default",
  components: {
    Pagination,
    Transaction,
    // BranchSales,
    // HelloWorld
  },

  mixins: [checkPermissionExist, FormatPrice],
  computed: mapGetters({
    user: "auth/user",
  }),

  data: () => ({
    transactions: [],
    searching: false,

    filter: "",
    onFilter: false,
    isLoading: false,

    filterMeterName: "",
    filterMeterNumber: "",
    filterAgent: "",
    filterDate: "",

    meterName: [],
    meterNumbers: [],
    agents: [],
    // staffs: [],

    // here2
    selectedTransaction: null,
  }),

  created() {
    var _this = this;
    _this.loadData(1);
    if (!this.checkPermissionExist(this.user.data, "transaction-read")) {
      this.$router.go(-1);
      return false;
    }
  },

  methods: {
    //
    rqFilter() {
      this.onFilter = !this.onFilter;

      if (this.onFilter) {
        this.loadAgents();
        this.loadStaffs();
      }
    },

    viewTrans(trans) {
      // here 3
      this.selectedTransaction = trans;
      this.$modal.show("example");
      // let data = {
      //   amount: trans.amount,
      //   branch: trans.branch,
      //   created: trans.created,
      //   customer: trans.customer,
      //   fee: trans.fee,
      //   id: trans.id,
      //   list_tokens: trans.list_tokens,
      //   meter_serial: trans.meter_serial,
      //   payment_date: trans.payment_date,
      //   receipt: trans.receipt,
      //   staff: trans.staff,
      //   total: trans.total,
      //   units: trans.units,
      // };

      // console.log(data);

      // this.$router.push({
      //   name: `transaction`, //use name for router push
      //   params: { data },
      // });
    },

    async loadData(pageNo, filter) {
      // Submit the form

      if (filter) {
        // filter = filter;
        this.searching = true;
        // this.isLoading = false;
      } else {
        filter = "";
        this.searching = false;
        // this.isLoading = false;
      }

      this.isLoading = true;
      try {
        const { data } = await axios.get(
          `/tx/agent/transactions?page=${pageNo}&filter=${filter}&date=${this.filterDate}`
        );

        console.log("dataaaa", data);

        this.transactions = data;
        // this.agents
        this.searching = false;
        this.isLoading = false;

        return true;
      } catch (e) {
        console.log("Error");
        this.searching = false;
        this.isLoading = false;

        return false;
      }
    },

    search() {
      var vm = this;
      this.searching = true;
      vm.loadData(1, vm.filter);
    },

    formatDate(date) {
      var day = new Date(date);

      return moment().utc(day).format("Do MMM, YYYY, h:mm:ss a");
      // console.log(day.format('dddd MMMM Do YYYY, h:mm:ss a'));
    },

    async loadStaffs() {
      // Submit the form

      try {
        const { data } = await axios.get(`/staff/all`);

        this.staffs = data.data;
        // this.isLoading = false;

        return true;
      } catch (e) {
        console.log("Error");
        this.isLoading = false;
        return false;
      }
    },

    // async loadAgents() {
    //   // Submit the form

    //   try {
    //     const { data } = await axios.get(`agents?page=1&filter=`);

    //     this.agents = data;
    //     // this.isLoading = false;
    //     console.log("agentsssssssssssss", this.agents);

    //     return true;
    //   } catch (e) {
    //     console.log("Error");
    //     this.isLoading = false;
    //     return false;
    //   }
    // },
  },
};
</script>
