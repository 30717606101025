<template>
    <div class="h-full">
        <div
            v-if="errMessage != '' && !isLoadingTrans"
            class="h-full flex justify-center items-center"
        >
            {{ errMessage }}
        </div>
        <div
            v-if="!loading && errMessage === ''"
            class="all-container mb-6 border"
        >
            <div class="flex-1 px-3 mb-6 md:mb-0 bg-white">
                <div class="input-wrapper py-6 flex justify-center">
                    <div v-if="data.status_val == 'PROCESSING'">
                        <p>
                            <b
                                >The request for a retry has been sent. Refresh
                                the page to confirm that the transaction has
                                been successfully processed</b
                            >
                            <button
                                @click="window.location.reload()"
                                class="text-blue-600 hover:text-blue-500"
                            >
                                Refresh
                            </button>
                        </p>
                    </div>
                    <div
                        v-if="data.status_val == 'PROCESSED'"
                        id="section-to-print"
                        style="margin-top: 0px"
                        class=""
                    >
                        <div id="invoice-POS">
                            <div id="top">
                                <div class="info">
                                    <h2
                                        style="
                                            font-weight: 700;
                                            font-size: 20px;
                                        "
                                    >
                                        Chapman Xpress ({{ data.branch }})
                                    </h2>
                                </div>
                            </div>
                            <!--End InvoiceTop-->

                            <div id="mid" style="padding-bottom: 20px">
                                <div class="info">
                                    <p>
                                        {{ 'ExpiryDate' }} <br />
                                        Receipt No . : {{ data.receipt }} <br />
                                        Meter No . : {{ data.meter_serial }}
                                        <br />
                                    </p>
                                    <p style="margin-top: 5px">
                                        <b>{{ data.customer }}</b
                                        ><br />
                                        {{ data.meter_serial }}
                                    </p>
                                </div>
                            </div>
                            <!--End Invoice Mid-->

                            <div id="bot">
                                <div class="token">
                                    <p>Token Number</p>
                                    <p>
                                        ************************************************
                                    </p>
                                    <p
                                        style="
                                            font-size: 26px;
                                            margin-bottom: 10px;
                                        "
                                    >
                                        <!-- <b>{{ formatToken(data.list_tokens[0]) }}</b> -->
                                        <b
                                            class="block font-extrabold"
                                            v-for="(
                                                token, i
                                            ) in data.list_tokens"
                                            :key="i"
                                        >
                                            {{ formatToken(token) }}
                                        </b>
                                    </p>
                                    <p>
                                        ************************************************
                                    </p>
                                </div>
                            </div>

                            <div id="mid">
                                <div class="info">
                                    <p>
                                        Amount Paid . : GMD {{ data.total
                                        }}<br />
                                        Fee . : GMD {{ data.fee }} <br />
                                        Cashpower Amount . : {{ data.amount }}
                                        <br />
                                        Total Units . : {{ data.units }}.kWh
                                        <br />
                                        Payment Date . :
                                        {{ data.created }} <br />
                                    </p>
                                    <p style="margin: 20px 0px 20px 0px">
                                        Use Energy Wisely!
                                    </p>
                                </div>
                            </div>
                            <p style="margin-top: 10px; font-size: 12px">
                                Phone: +220 438 3223 / 307 4455
                            </p>
                            <!--End InvoiceBot-->
                        </div>
                        <!--End Invoice-->
                    </div>
                </div>
            </div>
        </div>
        <div class="h-full flex justify-center items-center" v-else>
            Loading...
        </div>
    </div>
</template>

<script>
import FormatPrice from '@/mixins/FormatPrice';
import { mapGetters } from 'vuex';

export default {
    props: ['data', 'loading', 'errMessage'],

    data: () => ({
        transactions: [],

        print2default: true,
        selectedPrint: null,
    }),

    mixins: [FormatPrice],
    computed: mapGetters({
        user: 'auth/user',
    }),

    methods: {
        formatToken(token) {
            return token.match(/\d{4}(?=\d{2,3})|\d+/g).join('-');
        },
    },
};
</script>

<style scoped>
.all-container {
    width: 100%;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    flex-wrap: wrap;
}

.heading {
    font-size: 24px;
    font-weight: bold;
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
    text-align: center;
    max-width: 300px;
}

.content-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
    min-height: 90px;
}

.content-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 10px;
}

.content-item small {
    color: #888;
    margin-bottom: 4px;
    font-size: 13px;
}

.content-item h3 {
    font-size: 1rem;
}

@media print {
    body * {
        visibility: hidden;
    }

    #hide-to-print #hide-to-print * {
        visibility: hidden;
    }

    .__hide__to_print {
        visibility: hidden;
    }

    #section-to-print,
    #section-to-print * {
        width: 100mm;
        /* background: #333; */

        visibility: visible;
    }

    #section-to-print {
        position: absolute;
        left: 0;
        top: 0;
    }
}

#invoice-POS {
    /* box-: 0 0 1in -0.25in rgba(0, 0, 0, 0.5); */
    /* padding: 2mm; */
    /* margin: 0 auto; */
    /* width: 100mm; */
    /* background: rgb(168, 168, 168); */
}

#invoice-POS ::selection {
    background: #f31544;
    color: #fff;
}

#invoice-POS ::moz-selection {
    background: #f31544;
    color: #fff;
}

#invoice-POS h1 {
    font-size: 1.5em;
    color: #000;
}

#invoice-POS h2 {
    font-size: 0.9em;
}

#invoice-POS h3 {
    font-size: 1.2em;
    font-weight: 300;
    line-height: 2em;
}

#invoice-POS p {
    font-size: 1.4em;
    color: #000;
    line-height: 1.2em;
}

#invoice-POS #top,
#invoice-POS #mid,
#invoice-POS #bot {
    /* Targets all id with 'col-' */
    margin-top: 0px;
    margin-bottom: 20px;
    /* border-bottom: 1px solid #eee; */
}

#invoice-POS #bot .token {
    text-align: center;
    width: '100%';
}

#invoice-POS #top {
    /* min-height: 100px; */
}

#invoice-POS #mid {
    /* min-height: 80px; */
}

#invoice-POS #bot {
    /* min-height: 50px; */
}

/* #invoice-POS #top .logo {
  height: 60px;
  width: 60px;
  background: url(../assets/logo_icon.png) no-repeat;
  background-size: 60px 60px;
}
#invoice-POS .clientlogo {
  float: left;
  height: 60px;
  width: 60px;
  background: url(../assets/logo_icon.png) no-repeat;
  background-size: 60px 60px;
  border-radius: 50px;
} */
#invoice-POS .info {
    display: block;
    margin-left: 0;
}

#invoice-POS .title {
    float: right;
}

#invoice-POS .title p {
    text-align: right;
}

#invoice-POS table {
    width: 100%;
    border-collapse: collapse;
}

#invoice-POS .tabletitle {
    font-size: 0.5em;
    background: #eee;
}

#invoice-POS .service {
    /* border-bottom: 1px solid #eee; */
}

#invoice-POS .item {
    width: 24mm;
}

#invoice-POS .itemtext {
    font-size: 0.5em;
}

#invoice-POS #legalcopy {
    /* margin-top: 5mm; */
}
</style>
